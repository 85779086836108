/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality

// import Inquiry from './inquiry';

import auth from './auth'
import user from './user'
import cashout from './cashout'
import virtualAccount from './virtual-account'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  modules: {
    authModule: auth,
    // userModule: user,
    cashoutModule: cashout,
    virtualAccountModule: virtualAccount
  }
})

export default store
