/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
// import store from '../stores'
export default [
  {
    path: '*',
    meta: {
      name: 'main',
      requiresAuth: false,
    },
    redirect: {
      path: '/login',
    },
  },
  {
    path: '/login',
    meta: {
      name: 'Login View',
      // requiresAuth: false,
    },
    component: () => import(`@/views/auth/LoginPage.vue`),
  },
  {
    path: '/update-password',
    component: () => import(`@/views/auth/UpdatePasswordPage.vue`),
  },
  {
    path: '/register',
    meta: {
      name: 'Login View',
      // requiresAuth: false,
    },
    component: () => import(`@/views/auth/RegisterPage.vue`),
  },
  {
    path: '/forgot-password',
    meta: {
      name: 'Login View',
      // requiresAuth: false,
    },
    component: () => import(`@/views/auth/ForgotPasswordPage.vue`),
  },
  {
    path: '/',
    redirect: {
      path: '/dashboard',
    },
    // meta: {
    //   requiresAuth: true
    // },
    component: () => import('@/components/organism/Dashboard'),
    children: [
      {
        path: '/dashboard',
        meta: {
          name: 'Home',
        },
        component: () => import(`@/views/HomePage.vue`)
      },
      {
        path: '/topup',
        meta: {
          name: 'Topup',
        },
        component: () => import(`@/views/TopupPage.vue`),
      },
      {
        path: '/cashout',
        meta: {
          name: 'Cashout',
        },
        component: () => import(`@/views/CashoutPage.vue`),
      },
      {
        path: '/cashout/:id',
        meta: {
          name: 'Cashout',
        },
        component: () => import('@/views/cashout/DetailPage.vue'),
      },
      {
        path: '/account',
        meta: {
          name: 'Account',
        },
        component: () => import(`@/views/AccountPage.vue`),
      },
      {
        path: '/setting',
        meta: {
          name: 'Setting',
        },
        component: () => import(`@/views/SettingPage.vue`),
      },
    ]
  },
  // {
  //   path: '/dashboard',
  //   meta: {
  //     name: 'Dashboard View',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Home',
  //       },
  //       component: () => import(`@/views/HomePage.vue`)
  //     },
  //   ],
  // },
  // {
  //   path: '/topup',
  //   meta: {
  //     name: 'Transaction',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Topup',
  //       },
  //       component: () => import(`@/views/TopupPage.vue`),
  //     },
  //   ]
  // },
  // {
  //   path: '/users',
  //   meta: {
  //     name: 'Users',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Users',
  //       },
  //       component: () => import(`@/components/Users.vue`),
  //     },
  //   ]
  // },
  // {
  //   path: '/topup',
  //   meta: {
  //     name: 'Topup History',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Topup History',
  //       },
  //       component: () => import(`@/components/TopupHistory.vue`),
  //     },
  //   ]
  // },
  // {
  //   path: '/qris',
  //   meta: {
  //     name: 'QRIS',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'QRIS',
  //       },
  //       component: () => import(`@/components/Qris.vue`),
  //     },
  //   ]
  // },
  // {
  //   path: '/cashout',
  //   meta: {
  //     name: 'Cashout',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Cashout',
  //       },
  //       component: () => import(`@/views/CashoutPage.vue`),
  //     },
  //   ]
  // },
  // {
  //   path: '/account',
  //   meta: {
  //     name: 'Account',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Account',
  //       },
  //       component: () => import(`@/views/AccountPage.vue`),
  //     },
  //   ]
  // },
  // {
  //   path: '/setting',
  //   meta: {
  //     name: 'Setting',
  //     requiresAuth: false,
  //   },
  //   component: () => import(`@/views/pages/DashboardViews.vue`),
  //   children: [
  //     {
  //       path: '',
  //       meta: {
  //         name: 'Setting',
  //       },
  //       component: () => import(`@/views/SettingPage.vue`),
  //     },
  //   ]
  // },
];
