export default {
  SET_CASHOUTS(state, payload) {
    state.cashouts = payload
  },
  SET_META(state, payload) {
    state.meta = payload
  },
  SET_BANKS(state, payload) {
    state.banks = payload
  },
  SET_WITHDRAW(state, payload) {
    state.withdraw = payload
  },
  SET_DETAIL(state, payload) {
    state.detail = payload
  },
}