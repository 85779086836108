
/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
// import store from '../stores'
import Meta from 'vue-meta'

// Routes
import paths from './path'
import store from '@/stores'

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths,

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth
router.beforeEach((to, from, next) => {
  // console.log(to.matched.some(record => record.meta.requiresAuth))
  // console.log('🚀 | file: index.js | line 40 | router.beforeEach | to', to);
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (store.getters['authModule/isLoggedIn'] && to.path !== '/login') {
  //     return next('/dashboard')
  //   } else if (!store.getters['authModule/isLoggedIn'] && to.path == '/login') {
  //     return next('/login')
  //   }
    
  // }

  // next()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authorized) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }

})

Vue.use(Meta)

export default router
