import router from "@/routes";
import axios from "axios";
import dayjs from "dayjs";

const api_prod = axios.create({
  baseURL: 'https://deposit.zipay.id/v3/merchant-dashboard'
})

const api = axios.create({
  baseURL: 'https://depositdev.zipay.id/v3/merchant-dashboard'
})

api.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('access_token')
  // const refreshToken = localStorage.getItem('refresh_token')
  // const expiredAt = localStorage.getItem('expired_at')

  if (localStorage.getItem('env') === 'prod') {
    config.baseURL = 'https://deposit.zipay.id/v3/merchant-dashboard'
  }

  // console.log('accessToken', accessToken)

  // const token = await getRefreshToken(refreshToken)

  // console.log(token)

  // console.log(await getRefreshToken(refreshToken))

  // if (new Date().valueOf() >= new Date(expiredAt).valueOf()) {
  //   const token = await getRefreshToken(refreshToken)
  //   console.log('ok')
  //   console.log(token)
  //   config.headers['Authorization'] = `Bearer ${accessToken}`
  // } else {
  //   config.headers['Authorization'] = `Bearer ${accessToken}`
  // }

  
  config.headers['Authorization'] = `Bearer ${accessToken}`
  
  // console.log(config, accessToken)

  return config
}, async (error) => {
  console.log('error response', error)
  try {
    const refreshToken = localStorage.getItem('refresh_token')
    const rs = await api.post('/login/refresh', {refreshToken})
    const expiredAt = dayjs().add(rs.data.data.expires_in, 's').format('DD/MM/YYYY HH:mm:ss')

    localStorage.setItem('refresh_token', rs.data.data.refresh_token)
    localStorage.setItem('access_token', rs.data.data.access_token)
    localStorage.setItem('expired_at', expiredAt)

    return rs.data.data.access_token
  } catch (_error) {
    console.log(_error)
    localStorage.clear()
    router.push('/login')
  }
  return Promise.reject(error)
})

api.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalConfig = error.config

  if (originalConfig.url !== '/login' && error.response) {
    if (error.response.status === 401 && !originalConfig._retry) {

      try {
        const refreshToken = localStorage.getItem('refresh_token')
        const rs = await api.post('/login/refresh', {
          refreshToken
        })
        const expiredAt = dayjs().add(rs.data.data.expires_in, 's').format('DD/MM/YYYY HH:mm:ss')

        localStorage.setItem('refresh_token', rs.data.data.refresh_token)
        localStorage.setItem('access_token', rs.data.data.access_token)
        localStorage.setItem('expired_at', expiredAt)

        // location.reload()

        return rs.data.data.access_token
      } catch (_error) {
        console.log(_error)
        localStorage.clear()

        router.push('/login')
      }
    }
  }

  return Promise.reject(error)
})

api_prod.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('access_token')
  // const refreshToken = localStorage.getItem('refresh_token')
  // const expiredAt = localStorage.getItem('expired_at')

  if (localStorage.getItem('env') === 'prod') {
    config.baseURL = 'https://deposit.zipay.id/v3/merchant-dashboard'
  }

  // console.log('accessToken', accessToken)

  // const token = await getRefreshToken(refreshToken)

  // console.log(token)

  // console.log(await getRefreshToken(refreshToken))

  // if (new Date().valueOf() >= new Date(expiredAt).valueOf()) {
  //   const token = await getRefreshToken(refreshToken)
  //   console.log('ok')
  //   console.log(token)
  //   config.headers['Authorization'] = `Bearer ${accessToken}`
  // } else {
  //   config.headers['Authorization'] = `Bearer ${accessToken}`
  // }

  
  config.headers['Authorization'] = `Bearer ${accessToken}`
  
  console.log(config)

  return config
}, async (error) => {
  console.log('error response', error)
  try {
    const refreshToken = localStorage.getItem('refresh_token')
    const rs = await api.post('/login/refresh', {refreshToken})
    const expiredAt = dayjs().add(rs.data.data.expires_in, 's').format('DD/MM/YYYY HH:mm:ss')

    localStorage.setItem('refresh_token', rs.data.data.refresh_token)
    localStorage.setItem('access_token', rs.data.data.access_token)
    localStorage.setItem('expired_at', expiredAt)

    // return rs.data.data.access_token
    location.reload()
  } catch (_error) {
    console.log(_error)
    localStorage.clear()
    router.push('/login')
  }
  return Promise.reject(error)
})

api_prod.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalConfig = error.config

  if (originalConfig.url !== '/login' && error.response) {
    if (error.response.status === 401 && !originalConfig._retry) {

      try {
        const refreshToken = localStorage.getItem('refresh_token')
        const rs = await api.post('/login/refresh', {
          refreshToken
        })
        const expiredAt = dayjs().add(rs.data.data.expires_in, 's').format('DD/MM/YYYY HH:mm:ss')

        localStorage.setItem('refresh_token', rs.data.data.refresh_token)
        localStorage.setItem('access_token', rs.data.data.access_token)
        localStorage.setItem('expired_at', expiredAt)

        location.reload()

        // return rs.data.data.access_token
      } catch (_error) {
        console.log(_error)
        localStorage.clear()

        router.push('/login')
      }
    }
  }

  return Promise.reject(error)
})

export {api, api_prod}