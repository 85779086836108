export default {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_REFRESH_TOKEN(state, payload) {
    state.refreshToken = payload
  },
  SET_ACCESS_TOKEN(state, payload) {
    state.accessToken = payload
  },
  SET_EXPIRED_AT(state, payload) {
    state.expiredAt = payload
  },
  SET_BALANCE(state, payload) {
    state.balance = payload
  },
}