import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import splitPane from 'vue-splitpane';

Vue.use(Vuetify);
Vue.component('split-pane', splitPane);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2962FF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        zipay: '#FC6347',
        zipay2: '1F2A44',
        black: '#000000',
        softblue: '#F0F3F8',
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
});
