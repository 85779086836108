import { api, api_prod } from "@/libs/api"

export default {
  async getCashout({commit}, params) {
    try {
      const response = await api_prod.post('/cash-out/history', params)
      const { rows, count } = response.data.data
      const totalPage = Math.ceil(count / params.limit)
      const meta = {
        ...params,
        total_page: totalPage,
        total_data: count
      }

      commit('SET_CASHOUTS', rows)
      commit('SET_META', meta)
    } catch (error) {
      console.log(error)
    }
  },
  async getBanks({commit}) {
    try {
      const response = await api_prod.get('/cash-out/bank/list')

      commit('SET_BANKS', response.data.data)
    } catch (error) {
      console.log(error)
    }
  },
  async withdraw({commit}, {body, account}) {
    // const payload = {
    //   amount: this.cashoutPayload.amount,
    //   destinationAccountNo: this.account.number,
    //   bankId: this.account.bankId,
    //   msisdn: this.cashoutPayload.msisdn
    // }

    try {
      const response = await api_prod.post('/cash-out/direct', body)

      const withdraw = {
        bankHolder: account.holder,
        bankAccount: account.number,
        adminFee: response.data.data.adminFee,
        bankName: response.data.data.bankName,
        phone: response.data.data.msisdn,
        referenceId: response.data.data.referenceId,
        deductedAmount: response.data.data.deductedAmount,
        transferedAmount: response.data.data.transferedAmount
        // date: dayjs().format('DD MMMM YYYY'),
        // time: `${date.getHours()}:${date.getMinutes()}`
      }

      commit('SET_WITHDRAW', withdraw)

      // this.modals.withdraw = false
      // this.modals.vaSuccess = true
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getDetail({ commit }, payload) {
    try {
      const response = await api_prod.post(`/cash-out/history/${payload}`)

      commit('SET_DETAIL', response.data.data)
    } catch (error) {
      console.log(error)
    }
  }
}