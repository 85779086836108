import { api, api_prod } from "@/libs/api"

export default {
  async getVirtualAccount({commit}, params) {
    try {
      const response = await api_prod.post('/va/topup/history', params)
      const { count, rows } = response.data.data
      const totalPage = Math.ceil(count / params.limit)
      commit('SET_VIRTUAL_ACCOUNTS', rows)

      const meta = {
        ...params,
        total_page: totalPage,
        total_data: count
      }

      commit('SET_META', meta)
    } catch (error) {
      console.log(error)
    }
  },
  async getBanks({commit}) {
    try {
      const response = await api_prod.get('/va/bank/available')

      commit('SET_BANKS', response.data.data)
    } catch (error) {
      console.log(error)
    }
  },
  setVA({commit}, payload) {
    commit('SET_VA', payload)
  }
}