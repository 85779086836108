// import './components'

import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/routes';
import store from './stores';
// import axios from 'axios';
import i18n from '@/i18n';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '../public/custom.css';

// Vue.prototype.$http = axios;
// // Sets the default url used by all of this axios instance's requests
// axios.defaults.baseURL = process.env.VUE_APP_URL_CORE;
// axios.defaults.headers.get['Accept'] = 'application/json';

// const token = localStorage.getItem('token');

// if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

sync(store, router);

Vue.config.productionTip = false;

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
