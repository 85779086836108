import { api, api_prod } from "@/libs/api"
import router from "@/routes"
// import router from "@/routes"
import dayjs from "dayjs"

export default {
  async loggedIn({commit}, credential) {
    try {
      const login = await api_prod.post('/login', credential)
      const {refresh_token, expires_in, access_token, updatePassword} = login.data.data
      const expiredAt = dayjs().add(expires_in, 's').toISOString()

      localStorage.setItem('access_token', access_token)
      localStorage.setItem('refresh_token', refresh_token)
      localStorage.setItem('expired_at', expiredAt)

      const introspect = await api_prod.post('/login/introspect')
      const {username, email, active, prodStatus} = introspect.data.data

      const env = prodStatus ? 'prod' : 'demo'
      
      localStorage.setItem('env', env)

      commit('SET_USER', {username, email, prodStatus})
      commit('SET_ACCESS_TOKEN', access_token)
      commit('SET_REFRESH_TOKEN', refresh_token)
      commit('SET_EXPIRED_AT', expiredAt)

      const checkBalance = await api_prod.post('/partner/balance')

      localStorage.setItem('balance', JSON.stringify(checkBalance.data.data))
      localStorage.setItem('user', JSON.stringify({username, email, prodStatus}))

      commit('SET_BALANCE', checkBalance.data.data)

      // return true

      console.log(updatePassword)

      if (updatePassword) {
        router.replace('/update-password')
      } else {
        router.replace('/dashboard')
      }

    } catch (error) {
      console.log(error)

      // return false
    }
  },
  async register({commit}, payload) {
    try {
      const response = await api_prod.post('/partner/register/dashboard', payload)
      const {refresh_token, expires_in, access_token} = response.data.data
      const expiredAt = dayjs().add(expires_in, 's').toISOString()

      localStorage.setItem('access_token', access_token)
      localStorage.setItem('refresh_token', refresh_token)
      localStorage.setItem('expired_at', expiredAt)

      commit('SET_ACCESS_TOKEN', access_token)
      commit('SET_REFRESH_TOKEN', refresh_token)
      commit('SET_EXPIRED_AT', expiredAt)
    } catch (error) {
      console.log(error)
    }
  },
  async register1({commit}, payload) {
    try {
      const formData = new FormData

      formData.append('msisdn', payload.msisdn)
      formData.append('fullname', payload.fullname)
      formData.append('cardFullName', payload.cardFullName)
      formData.append('cardNumber', payload.cardNumber)
      formData.append('file', payload.file)

      await api_prod.post('/partner/register/owner', formData)
    } catch (error) {
      console.log(error)
    }
  },
  async register2({commit}, payload) {
    try {
      const formData = new FormData

      formData.append('businessName', payload.businessName)
      formData.append('msisdn', payload.msisdn)
      formData.append('category', payload.category)
      formData.append('businessType', payload.businessType)
      formData.append('address', payload.address)
      formData.append('kota', payload.kota)
      formData.append('kodePos', payload.kodePos)
      formData.append('file', payload.file)
      formData.append('kecamatan', payload.kecamatan)
      formData.append('kelurahan', payload.kelurahan)

      await api_prod.post('/partner/register/detail', formData)
    } catch (error) {
      this.$toast.error(error.response.data.message)
    }
  },
  async register3({commit}, payload) {
    try {
      await api_prod.post('/partner/register/bank', payload)

      alert('success')
    } catch (error) {
      this.$toast.error(error.response.data.message)
    }
  },
  async checkBalance({commit}) {
    try {
      const response = await api_prod.post('/partner/balance')

      localStorage.setItem('balance', JSON.stringify(response.data.data))
      
      commit('SET_BALANCE', response.data.data)      
    } catch (error) {
      console.log(error)
    }
  }
}