export default {
  SET_VIRTUAL_ACCOUNTS(state, payload) {
    state.virtualAccounts = payload
  },
  SET_META(state, payload) {
    state.meta = payload
  },
  SET_BANKS(state, payload) {
    state.banks = payload
  },
  SET_VA(state, payload) {
    state.vaSuccess = payload
  },
}